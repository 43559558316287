.MuiContainer-root.makeStyles-container-24.MuiContainer-maxWidthLg .Cell-dayOfWeek-70, .MuiContainer-root.makeStyles-container-24.MuiContainer-maxWidthLg .Cell-dayOfMonth-71 {
    color: rgba(0, 0, 0, 0.54);
}
.MuiContainer-root.makeStyles-container-24.MuiContainer-maxWidthLg .Label-text-82 {
    color: rgba(0, 0, 0, 0.54);
}
.MuiContainer-root.makeStyles-container-24.MuiContainer-maxWidthLg .Cell-highlightedText-66 {
    color: #009da0 !important;
    font-weight: bold;
}
.MuiFormControlLabel-root {
    color: #666;
}
.MuiFormControlLabel-root:hover {
    color: #666 !important;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.OverlayBase-absolutePosition-31.OverlayBase-paper-32.OverlayBase-halfSize-34.OverlayBase-transition-35.MuiPaper-elevation16 {
    width: 100%;
}
.schedule-info-icon:hover {
    color: #666;
}
.makeStyles-container-31 {
    overflow-x: hidden;
}
